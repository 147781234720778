<template>
  <v-alert
    class="alert-container"
    border="left"
    dense
    :type="type"
    v-show="enable"
    transition="slide-x-transition"
  >
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  name: "Alert",
  data() {
    return {};
  },
  props: {
    enable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "success",
    },
    message: {
      type: String,
      default: null,
    },
    timeoutEnable: {
      type: Boolean,
      default: false,
    },
    timeout: {
      type: Number,
      default: 2500,
    },
  },
  methods: {
    close() {
      setTimeout(() => {
        this.$emit("close");
      }, this.timeout);
    },
  },
  updated() {
    if (this.timeoutEnable && this.enable) {
      this.close();
    }
  },
};
</script>

<style scoped></style>
