var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.tableObj.headers,"items":_vm.tableObj.items,"loading":false,"loading-text":"data loading....","group-by":"categoryIndex","disablePagination":"","disableSort":"","disableFiltering":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
return [_c('th',{attrs:{"colspan":_vm.tableObj.headers.length}},[_vm._v(" "+_vm._s(items[0].category)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',_vm._l((_vm.tableObj.headers),function(header,ind){return _c('td',{key:ind,class:[header.align ? ("text-" + (header.align)) : '']},[(header.value === 'Issue' && _vm.useTooltip(item[header.value]))?[_c('v-tooltip',{attrs:{"right":"","max-width":"300","content-class":"tooltip-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.localString(item[header.value], item.category))+" ")])]}}],null,true)},[_c('h4',[_vm._v(_vm._s(_vm.concepts[item[header.value]].title))]),(!!_vm.concepts[item[header.value]].explanation)?_c('div',[_vm._v(" "+_vm._s(_vm.concepts[item[header.value]].explanation)+" ")]):_vm._e()])]:[_vm._v(" "+_vm._s(_vm.localString(item[header.value], item.category))+" ")]],2)}),0)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }