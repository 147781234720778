<template>
  <v-card>
    <h4 class="mx-2" v-if="name">{{ name }}</h4>

    <v-chip class="ma-2" label v-for="header in headers" :key="header.text">
      <label class="v-chip-label">{{ header.text }}</label>
      <a
        v-if="header.text === 'Website'"
        :href="dataObj[header.value]"
        target="_blank"
        >{{ dataObj[header.value] }}</a
      >
      <template v-else>
        {{
          typeof dataObj[header.value] === "number"
            ? dataObj[header.value].toLocaleString()
            : dataObj[header.value]
        }}
      </template>
    </v-chip>
  </v-card>
</template>

<script>
export default {
  name: "AssetProfileTable",
  props: {
    name: {
      type: String,
    },
    dataObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "City",
          value: "city",
        },
        {
          text: "State",
          value: "state",
        },
        {
          text: "Website",
          value: "website",
        },
        {
          text: "Overall Risk",
          value: "overallRisk",
        },
        {
          text: "Sector",
          value: "sector",
        },
        {
          text: "Industry",
          value: "industry",
        },
        {
          text: "Full Time Employees",
          value: "fullTimeEmployees",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.v-chip-label {
  font-size: 10px;
  font-weight: bold;
  color: darkgray;
  padding-right: 5px;
}
</style>
