<template>
  <v-row class="percentage-table" v-if="Object.keys(stockData).length">
    <v-col cols="12">
      <v-data-table
        dense
        class="elevation-1"
        disablePagination
        disableSort
        disableFiltering
        hide-default-footer
        :items="[percentageObj.data]"
        :headers="percentageObj.headers"
      >
        <template
          v-for="header in percentageObj.headers"
          v-slot:[`item.${header.value}`]="{ item }"
        >
          <span
            :key="header.value"
            :class="{ 'mark-red': item[header.value] < 0 }"
            >{{ item[header.value] }} %</span
          >
        </template>
      </v-data-table>
      <div class="avg-container">
        <v-tooltip bottom max-width="300" content-class="tooltip-content">
          <template v-slot:activator="{ on, attrs }">
            <v-chip class="ma-2" color="primary" v-bind="attrs" v-on="on">
              Expect avg: {{ percentageObj.avg.avg }}%
            </v-chip>
          </template>
          <h4>{{ concepts.Mean.title }}</h4>
          <div v-if="!!concepts.Mean.explanation">
            {{ concepts.Mean.explanation }}
          </div>
        </v-tooltip>
        <v-tooltip bottom max-width="300" content-class="tooltip-content">
          <template v-slot:activator="{ on, attrs }">
            <v-chip class="ma-2" color="primary" v-bind="attrs" v-on="on">
              Standard deviation: {{ percentageObj.avg.std }}
            </v-chip>
          </template>
          <h4>{{ concepts.STD.title }}</h4>
          <div v-if="!!concepts.STD.explanation">
            {{ concepts.STD.explanation }}
          </div>
        </v-tooltip>

        <v-tooltip bottom max-width="300" content-class="tooltip-content">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="ma-2"
              color="green"
              text-color="white"
              v-bind="attrs"
              v-on="on"
            >
              Expect olympic avg: {{ percentageObj.avg.olympicAvg }}%
            </v-chip>
          </template>
          <h4>{{ concepts.MeanOLY.title }}</h4>
          <div v-if="!!concepts.MeanOLY.explanation">
            {{ concepts.MeanOLY.explanation }}
          </div>
        </v-tooltip>
      </div>
    </v-col>

    <v-col cols="6">
      <Chart
        v-if="chartCandleData.series[0].data.length"
        type="candlestick"
        :title="`${symbol.toUpperCase()} Candlesticks per year`"
        :categories="chartCandleData.categories"
        :series="chartCandleData.series"
      />
    </v-col>

    <v-col cols="6">
      <Chart
        v-if="percentageChartData.data && percentageChartData.data.length"
        type="line"
        :title="percentageChartData.title"
        :categories="percentageChartData.categories"
        :series="[{ data: percentageChartData.data }]"
      />
    </v-col>
  </v-row>
</template>

<script>
const mathjs = require("mathjs");
import Chart from "./Chart";
import concepts from "../consts/concepts.json";

export default {
  name: "TenYearsPercentage",
  components: {
    Chart,
  },
  props: {
    symbol: {
      type: String,
      required: true,
    },
    stockData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      concepts,
    };
  },
  computed: {
    chartCandleData() {
      const data = Object.entries(this.stockData).map(([year, data]) => ({
        x: year,
        y: [
          this.roundNum(data.open),
          this.roundNum(data.high),
          this.roundNum(data.low),
          this.roundNum(data.close),
        ].map(Number),
      }));

      return {
        categories: Object.keys(this.stockData),
        series: [{ data: data }],
      };
    },

    percentageObj() {
      if (!this.stockData) return { avg: 0, data: [{}], headers: [] };
      const yearsObj = Object.entries(this.stockData).reduce(
        (acc, [year, yearObj]) => {
          const percentage = (yearObj.close / yearObj.open) * 100;
          const delta = Math.floor((percentage - 100) * 100) / 100;

          acc.headers.push({
            text: `${yearObj.monthOpen}-${yearObj.monthClose}/${year}`,
            value: year,
          });
          acc.avg.list.push(delta);
          acc.data[year] = delta;

          return acc;
        },
        {
          avg: {
            list: [],
          },
          data: {},
          headers: [],
        }
      );

      yearsObj.avg = {
        ...yearsObj.avg,
        avg: this.avg(yearsObj.avg.list),
        std: this.roundNum(this.std(yearsObj.avg.list)),
        olympicAvg: this.olympicAvg(yearsObj.avg.list),
      };

      return yearsObj;
    },

    percentageChartData() {
      return Object.entries(this.percentageObj.data).reduce(
        (acc, [year, percentage]) => {
          acc.categories.push(year);
          acc.data.push(percentage);

          return acc;
        },
        {
          categories: [],
          data: [],
          title: "Change in share value in percent per year",
        }
      );
    },
  },
  methods: {
    avg(List) {
      if (!List.length) return 0;
      return parseFloat(
        List.reduce((acc, value) => acc + value) / List.length
      ).toFixed(2);
    },

    std(List) {
      return mathjs.std(List, "unbiased");
    },

    olympicAvg(List, reduceEl = 2) {
      if (!List.length) return 0;
      for (let index = 0; index < reduceEl; index++) {
        const minValue = Math.min(...List);
        const maxValue = Math.max(...List);
        List = List.filter((e) => {
          return e !== minValue && e !== maxValue;
        });
      }
      return this.avg(List);
    },

    roundNum(num) {
      return parseFloat(num).toFixed(2);
    },
  },
};
</script>
