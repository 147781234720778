<template>
  <v-row class="financial-reports">
    <v-col cols="12">
      <v-btn-toggle dense v-model="togglePeriod" mandatory class="my-3">
        <v-btn v-for="option in historyOptions" :key="option" :value="option">
          {{ option }}
        </v-btn>
      </v-btn-toggle>
      <FinancialReports :items-arr="financialStatements" v-if="!!symbol" />
    </v-col>
  </v-row>
</template>

<script>
import FinancialReports from "./FinancialReports";
import requests from "../mixins/requests";
import { historyOptions } from "@/consts/const.json";

export default {
  name: "financialStatements",
  mixins: [requests],
  components: {
    FinancialReports,
  },
  props: {
    symbol: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      historyOptions,
      togglePeriod: null,
      financialReports: null,
    };
  },
  computed: {
    financialStatements() {
      if (!this.financialReports[this.togglePeriod].length) this.update();
      return this.financialReports[this.togglePeriod];
    },
  },
  methods: {
    getSymbolSummary(period = this.togglePeriod) {
      return this.get(
        `/backend/symbol-statements/${this.symbol}?period=${period}`
      );
    },
    async update() {
      if (this.financialReports[this.togglePeriod].length) return;
      this.financialReports[this.togglePeriod] = await this.getSymbolSummary();
    },
  },
  created() {
    this.financialReports = historyOptions.reduce(
      (acc, field) => ({
        ...acc,
        [field]: [],
      }),
      {}
    );
    this.togglePeriod = historyOptions[0];
  },
  async mounted() {
    this.financialReports.yearly = await this.getSymbolSummary(
      this.togglePeriod
    );
  },
};
</script>
