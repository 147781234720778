<template>
  <v-container>
    <v-row class="symbol-input">
      <v-col cols="4">
        <v-text-field
          label="stock symbol"
          v-model="symbolText"
          @keypress="getStockInfo($event)"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn @click="getStockInfo">get info</v-btn>
      </v-col>
      <v-col cols="6">
        <AssetProfileTable
          :data-obj="assetProfile"
          :name="quoteType.longName"
          v-if="assetProfile"
        />
      </v-col>
    </v-row>
    <TenYearsPercentage
      :symbol="symbol"
      :stockData="stockData"
      v-if="stockData"
    />
    <FinancialStatements :symbol="symbol" v-if="symbol" />
    <Alert
      type="error"
      :enable="!!alertMsg"
      :message="alertMsg"
      :timeoutEnable="true"
      @close="alertMsg = ''"
    />
  </v-container>
</template>

<script>
import AssetProfileTable from "./AssetProfileTable";
import TenYearsPercentage from "./TenYearsPercentage";
import FinancialStatements from "./FinancialStatements";
import Alert from "./Alert";
import requests from "../mixins/requests";

export default {
  name: "YearStock",
  mixins: [requests],
  components: {
    AssetProfileTable,
    TenYearsPercentage,
    FinancialStatements,
    Alert,
  },
  data() {
    return {
      symbol: null,
      symbolText: "",
      stockData: null,
      assetProfile: null,
      quoteType: null,
      alertMsg: "",
    };
  },

  methods: {
    async getStockInfo(event) {
      if (!this.symbolText) return;
      if (event.type === "keypress" && event.key !== "Enter") {
        return;
      }
      this.init();
      this.stockData = await this.getYearsStock(this.symbolText.toUpperCase());
      if (!this.stockData) {
        this.init();
        this.alertMsg = `Error occurred - Is symbol correct?`;
        return;
      }
      this.symbol = this.symbolText.toUpperCase();
      const resSummary = await this.getSymbolSummary(this.symbol);
      this.assetProfile = resSummary.assetProfile;
      this.quoteType = resSummary.quoteType;
    },
    init() {
      this.symbol = null;
      this.stockData = null;
      this.assetProfile = null;
      this.quoteType = null;
    },
    getYearsStock(symbol) {
      return this.get(`/backend/stock-years-candles/${symbol}`);
    },
    getSymbolSummary(symbol) {
      return this.get(`/backend/symbol-summary/${symbol}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.mark-red {
  color: red;
}
</style>
