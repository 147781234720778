<template>
  <div>
    <v-data-table
      dense
      class="elevation-1"
      :headers="tableObj.headers"
      :items="tableObj.items"
      :loading="false"
      loading-text="data loading...."
      group-by="categoryIndex"
      disablePagination
      disableSort
      disableFiltering
      hide-default-footer
    >
      <template v-slot:group.header="{ items }">
        <th :colspan="tableObj.headers.length">
          {{ items[0].category }}
        </th>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td
            v-for="(header, ind) in tableObj.headers"
            :key="ind"
            :class="[header.align ? `text-${header.align}` : '']"
          >
            <template
              v-if="header.value === 'Issue' && useTooltip(item[header.value])"
            >
              <v-tooltip right max-width="300" content-class="tooltip-content">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ localString(item[header.value], item.category) }}
                  </span>
                </template>
                <h4>{{ concepts[item[header.value]].title }}</h4>
                <div v-if="!!concepts[item[header.value]].explanation">
                  {{ concepts[item[header.value]].explanation }}
                </div>
              </v-tooltip>
            </template>
            <template v-else>
              {{ localString(item[header.value], item.category) }}
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import requests from "../mixins/requests";
import concepts from "@/consts/concepts.json";

export default {
  name: "FinancialReports",
  mixins: [requests],
  props: {
    itemsArr: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      concepts,
    };
  },
  computed: {
    tableObj() {
      if (!this.itemsArr.length) return { headers: [], items: [] };

      const headers = this.generateHeaders(this.itemsArr[0]);
      const categoryList = new Set();
      const items = this.itemsArr.map((m) => {
        if (!categoryList.has(m.category)) categoryList.add(m.category);
        return {
          ...m,
          categoryIndex: categoryList.size,
        };
      });

      return { headers, items };
    },
    conceptKeys() {
      return Object.keys(concepts);
    },
  },
  methods: {
    useTooltip(content) {
      return this.conceptKeys.includes(content);
    },
    generateHeaders(dataObj) {
      return Object.keys(dataObj)
        .filter((f) => !f.startsWith("category"))
        .map((key, index) => ({
          value: key,
          text: key,
          align: !index ? "start" : "end",
        }));
    },
    localString(param, category) {
      if (typeof param !== "number") return param;
      if (category.includes("Ratio")) {
        return Math.round(param * 100) + " %";
      }

      return (param / 1000).toLocaleString() + " K";
    },
  },
};
</script>

<style lang="scss" scoped></style>
