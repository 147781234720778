<template>
  <VueApexCharts height="350" :options="chartOptions" :series="series" />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "Chart",
  components: {
    VueApexCharts,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    // series: [
    //   {
    //     data: [
    //       {
    //         x: new Date(1538778600000),
    //         y: [6629.81, 6650.5, 6623.04, 6633.33],
    //       },
    //       {
    //         x: new Date(1538780400000),
    //         y: [6632.01, 6643.59, 6620, 6630.11],
    //       },
    //       {
    //         x: new Date(1538782200000),
    //         y: [6630.71, 6648.95, 6623.34, 6635.65],
    //       },
    //     ],
    //   },
    // ],
  }),
  computed: {
    chartOptions() {
      return {
        chart: {
          type: this.type,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "20px",
          },
        },
        title: {
          text: this.title,
          align: "left",
        },
        xaxis: {
          categories: this.categories,
          tickPlacement: "between",
        },
        yaxis: {
          tooltip: {
            enabled: true,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
