<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <h3 class="d-flex align-center">{{ header }}</h3>
      <div class="mx-2 remark">This site use yahoo finance</div>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <Main />
    </v-main>
  </v-app>
</template>

<script>
import Main from "./components/Main";
import { header } from "./consts/const.json";

export default {
  name: "App",

  components: {
    Main,
  },

  data: () => ({
    header,
  }),
};
</script>

<style lang="scss">
ul {
  list-style: none;
}
ul > li {
  padding: 5px;
}
.remark:before {
  content: "-";
  padding-right: 5px;
}
.tooltip-content {
  direction: rtl;
  font-size: 11px;
  font-family: sans-serif;
  line-height: 14px;
}
</style>
