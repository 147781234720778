var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Object.keys(_vm.stockData).length)?_c('v-row',{staticClass:"percentage-table"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","disablePagination":"","disableSort":"","disableFiltering":"","hide-default-footer":"","items":[_vm.percentageObj.data],"headers":_vm.percentageObj.headers},scopedSlots:_vm._u([_vm._l((_vm.percentageObj.headers),function(header){return {key:("item." + (header.value)),fn:function(ref){
var item = ref.item;
return [_c('span',{key:header.value,class:{ 'mark-red': item[header.value] < 0 }},[_vm._v(_vm._s(item[header.value])+" %")])]}}})],null,true)}),_c('div',{staticClass:"avg-container"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"300","content-class":"tooltip-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"primary"}},'v-chip',attrs,false),on),[_vm._v(" Expect avg: "+_vm._s(_vm.percentageObj.avg.avg)+"% ")])]}}],null,false,3962889209)},[_c('h4',[_vm._v(_vm._s(_vm.concepts.Mean.title))]),(!!_vm.concepts.Mean.explanation)?_c('div',[_vm._v(" "+_vm._s(_vm.concepts.Mean.explanation)+" ")]):_vm._e()]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300","content-class":"tooltip-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"primary"}},'v-chip',attrs,false),on),[_vm._v(" Standard deviation: "+_vm._s(_vm.percentageObj.avg.std)+" ")])]}}],null,false,162495768)},[_c('h4',[_vm._v(_vm._s(_vm.concepts.STD.title))]),(!!_vm.concepts.STD.explanation)?_c('div',[_vm._v(" "+_vm._s(_vm.concepts.STD.explanation)+" ")]):_vm._e()]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300","content-class":"tooltip-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},'v-chip',attrs,false),on),[_vm._v(" Expect olympic avg: "+_vm._s(_vm.percentageObj.avg.olympicAvg)+"% ")])]}}],null,false,2639943410)},[_c('h4',[_vm._v(_vm._s(_vm.concepts.MeanOLY.title))]),(!!_vm.concepts.MeanOLY.explanation)?_c('div',[_vm._v(" "+_vm._s(_vm.concepts.MeanOLY.explanation)+" ")]):_vm._e()])],1)],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.chartCandleData.series[0].data.length)?_c('Chart',{attrs:{"type":"candlestick","title":((_vm.symbol.toUpperCase()) + " Candlesticks per year"),"categories":_vm.chartCandleData.categories,"series":_vm.chartCandleData.series}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.percentageChartData.data && _vm.percentageChartData.data.length)?_c('Chart',{attrs:{"type":"line","title":_vm.percentageChartData.title,"categories":_vm.percentageChartData.categories,"series":[{ data: _vm.percentageChartData.data }]}}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }